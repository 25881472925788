import React, { useState } from "react";
import moment from 'moment';

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import { getKey } from "@utils/storage";
import { LOCALSTORAGE } from "@utils/constants";
import { CHATBOT_TYPE } from "@containers/HelpCenter/APIs/constants";

import './style.scss';

const TicketFeedbackHC = ({
    ticketId,
    chatbotType,
}) => {
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [thumbsUp, setThumbsUp] = useState(false);
    const [thumbsDown, setThumbsDown] = useState(false);

    const handleThumbsClick = (thumbsUpValue = false) => {
        if (thumbsUpValue) {
            setThumbsUp(thumbsUpValue);
        } else {
            setThumbsDown(!thumbsUpValue);
        }
        trackEvents(thumbsUpValue);
        setFeedbackSubmitted(true);
    };

    const trackEvents = (thumbsUpValue) => {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_TICKET_FEEDBACK, {
            [MIXPANEL.PARAMETER.HC_FEEDBACK]: thumbsUpValue ? MIXPANEL.VALUE.HC_THUMBS_UP : MIXPANEL.VALUE.HC_THUMBS_DOWN,
            [MIXPANEL.PARAMETER.HC_FEEDBACK_DATE]: moment().format("DD-MM-YYYY"),
            [MIXPANEL.PARAMETER.HC_FEEDBACK_TIME]: moment().format("HH:mm:ss"),
            [MIXPANEL.PARAMETER.HC_TICKET_ID]: ticketId,
            [MIXPANEL.PARAMETER.ACCOUNT_ID]: userInfo?.baId,
        });
    };

    const getFooterText = () => {
        if (thumbsUp) {
            return "Thank you for the Thumbs Up! Happy Binge-ing";
        } else if (thumbsDown) {
            return "We apologise for your experience. We are committed to improve our services in the future.";
        } else {
            return "Hope your issue was resolved";
        }
    }

    return (
        <div className={`ticket-feedback-form ${chatbotType === CHATBOT_TYPE.S360 && "s360-ticket-block"}`}>
            <div className="heading">{feedbackSubmitted ? "Your Feedback :" : "Share Your Feedback :"}</div>
            <ul className="feedback-icons">
                {(thumbsUp || !feedbackSubmitted) && <li
                    className={thumbsUp ? `active` : ""}
                    onClick={() => handleThumbsClick(true)}
                >
                    <div className="thumb-up">&nbsp;</div>
                </li>}
                {(thumbsDown || !feedbackSubmitted) && <li
                    className={thumbsDown ? `active` : ""}
                    onClick={() => handleThumbsClick(false)}
                >
                    <div className="thumb-down">&nbsp;</div>
                </li>}
            </ul>
            <div className="footer">{getFooterText()}</div>
        </div>)
}

export default TicketFeedbackHC;