import {
    CATEGORY_NAME,
    CONTENTTYPE,
    CONTRACT,
    GET_TA_REF_ID,
    JOURNEY_INITIATED_SCREEN,
    LAYOUT_TYPE,
    LOCALSTORAGE,
    MESSAGE,
    PARTNER_SUBSCRIPTION_TYPE,
    PLAY_ACTION,
    SECTION_SOURCE,
    SECTION_TYPE,
    TA_MAX_CONTENT
} from "@constants";
import MIXPANEL from "@constants/mixpanel";
import MOENGAGE from "@constants/moengage";
import {
    checkPartnerPlayable,
    getAnalyticsContentType,
    getAnalyticsRailCategory,
    getAnalyticsRailCategoryRegular,
    getAnalyticsSource,
    getContentLanguage,
    getPrimaryLanguage,
    setTaUseCaseId,
    isFreeContentEvent,
    isUserloggedIn,
    isFreeContentMixpanel,
    providerImage,
    getAllIntegratedPartnerList,
    checkAppleStatus,
    appleRedemptionClaimedDate,
    getRefUseCase,
    isPartnerCategoryBrowseSearchPage,
    getUserPackInfo,
    getVerbiages,
    appleActivateVideoPopup,
    checkPartnerSubscribed,
    getSearchParamsAsObject,
    generateUuidV4String,
    decryptUrl,
    getGenericAuthType,
    showAmazonActivityToast,
    isAmazonClaimClick,
    primeMixpanelParameter,
    appleStatusJouney,
    isNewPlayCtaJourney,
    getPartnerSubscriptionType,
    isContentLive,
} from "@utils/common";
import { deleteKey, getKey, setKey } from "@utils/storage";
import get from "lodash/get";
import queryString from "querystring";
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@constants/appsFlyer";
import mixPanelConfig from "@utils/mixpanel";
import { PROVIDER_NAME, APPLE_REDEMPTION_STATUS, DISTRO_TRACKING_URL, DISTRO_TRACK_EVENT_ARRAY, DISTRO_TRACK_EVENT_KEY, LIVE_PLAYBACK_TYPE, DISTRO_EVENT_NAME, DISTRO_MACRO_ARRAY, DISTRO_MACRO_KEY, PRIME_ENTITLEMENT_STATUS, PLAY_AUTH_TYPE, GENERIC_DEVICE_OS, DISTRO_MACRO_VALUES } from '@utils/constants/playerConstant';
import { closePopup, openPopup } from '@common/Modal/action';
import store from "@src/store";
import { MODALS } from '@common/Modal/constants';
import moment from "moment";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import isEmpty from "lodash/isEmpty";
import { URL as ROUTE_URL } from "@utils/constants/routeConstants";
import { getGenericDrm, sendDistroTrackEvent } from "@containers/PlayerWeb/APIs/actions";
import CryptoJS from 'crypto-js';
import { BROWSER_TYPE } from "@utils/constants/browser";
import { getSystemDetails } from "@utils/browserEnvironment";
import { getCurrentSubscriptionMyplanVerbiages, getPartnerStatus, getPrimeEntitlementDetails } from "@containers/Subscription/APIs/action";

export const getTitleAndDesc = (meta, contentType, desc = false, parentTitle = false) => {
    //parentContentType - stores the parent of the content type to be used in mixpanel parent title tracking
    let updatedContentType = contentType, isTvShow = false, parentContentType = contentType;
    //Added to check if the content type is a tv show then updated content type should also be tv show so that we show tv show rekated title and desc and not of parent i.e. brand/series - TSF-20158
    if ([CONTENTTYPE.TYPE_TV_SHOWS, CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL].includes(meta?.contentType)) {
        updatedContentType = meta.contentType;
        isTvShow = true;
    }

    if ([CONTENTTYPE.TYPE_BRAND, CONTENTTYPE.TYPE_BRAND_CHILD, CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL].includes(updatedContentType)) {
        return desc ? meta?.brandDescription || meta?.seriesDescription : meta?.brandTitle || meta?.seriesTitle;
    } else if ([CONTENTTYPE.TYPE_SERIES, CONTENTTYPE.TYPE_SERIES_CHILD, CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL].includes(updatedContentType)) {
        return desc ? meta?.seriesDescription : meta?.seriesTitle;
    } else {
        if (desc) {
            return (meta?.description || meta?.vodDescription || '');
        }
        //Added this check to get the parent title of tv show i.e. brand/series recorded in mixpanel properties
        else if (parentTitle && isTvShow) {
            if (parentContentType === CONTENTTYPE.TYPE_BRAND) {
                return meta?.brandTitle;
            }
            else if (parentContentType === CONTENTTYPE.TYPE_SERIES) {
                return meta?.seriesTitle;
            } else {
                return (meta?.vodTitle || meta?.title || '');
            }
        }
        //This shows the title of the vod/tv show on ui
        else {
            return (meta?.vodTitle || meta?.title || '');
        }

    }
}

export const getLiveContentValue = (isLiveContent, detail) => {
    if (isLiveContent) {
        return MIXPANEL.VALUE.CAPITALIZED_YES;
    } else {
        if (detail?.isLiveContent) {
            return MIXPANEL.VALUE.CAPITALIZED_YES;
        } else {
            return MIXPANEL.VALUE.CAPITALIZED_NO;
        }
    }
};

export const getAnalyticsData = (analytics = MIXPANEL, isForDeepLink = false, props, state) => {
    const storeState = store.getState();
    const { meta = {}, channelMeta } = props;
    const { mixpanelData: { conPosition, sectionType, contentSectionSource, sectionSource } } = state;
    const { updatedContentType } = getParentContentData(meta);
    const detail = get(storeState.PIDetails.data, 'detail');
    const currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data');

    let partnerInfo = JSON.parse(getKey(LOCALSTORAGE.PARTNER_INFO)) || [];
    const contentAuth = (isFreeContentEvent(detail?.contractName, partnerInfo, meta?.partnerId, currentSubscription?.subscriptionStatus) ||
        (meta?.partnerSubscriptionType?.toLowerCase() === PARTNER_SUBSCRIPTION_TYPE.PREMIUM.toLowerCase()));
    let isLiveContent = isContentLive(meta?.contentType);

    let commonData = {
        [`${analytics.PARAMETER.CONTENT_TITLE}`]: isLiveContent ? channelMeta?.channelName : getTitleAndDesc(meta, meta?.contentType),
        [`${analytics.PARAMETER.CONTENT_TYPE}`]: contentSectionSource?.toUpperCase() || meta?.contentType?.toUpperCase() || '',
        [`${analytics.PARAMETER.CONTENT_GENRE}`]: meta?.genre?.join(),
        // [`${analytics.PARAMETER.PARTNER_NAME}`]: meta?.provider,
        [`${analytics.PARAMETER.RAIL_TYPE}`]: sectionSource?.toUpperCase() || "",
        [`${analytics.PARAMETER.RAIL_CATEGORY}`]: getAnalyticsRailCategoryRegular(sectionType, analytics) || "",
        [`${analytics.PARAMETER.CONTENT_PARTNER}`]: isLiveContent ? meta?.provider || MIXPANEL.VALUE.TATAPLAY : (meta?.provider || ""),
        [`${analytics.PARAMETER.CONTENT_AUTH}`]: contentAuth ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO,
        [`${analytics.PARAMETER.CONTENT_CATEGORY}`]: updatedContentType || "",
        [`${analytics.PARAMETER.CONTENT_POSITION}`]: conPosition || "",
        //passed parent content type so that when we fetch title we get title of the parent always
        [`${analytics.PARAMETER.CONTENT_PARENT_TITLE}`]: isLiveContent ? channelMeta?.channelName : getTitleAndDesc(meta, meta?.parentContentType, false, true),
        [`${analytics.PARAMETER.AUTO_PLAYED}`]: isUserloggedIn() && isLiveContent ? MIXPANEL.VALUE.CAPITALIZED_YES : MIXPANEL.VALUE.CAPITALIZED_NO,
        [`${analytics.PARAMETER.LIVE_CONTENT}`]: getLiveContentValue(isLiveContent, meta),
    };
    let deeplinkReqData = {
        [`${analytics.PARAMETER.DURATION_SECONDS}`]: '',
        [`${analytics.PARAMETER.DURATION_MINUTES}`]: '',
        [`${analytics.PARAMETER.START_TIME}`]: '',
        [`${analytics.PARAMETER.STOP_TIME}`]: '',
        [`${analytics.PARAMETER.BUFFER_DURATION_MINUTES}`]: '',
        [`${analytics.PARAMETER.BUFFER_DURATION_SECONDS}`]: '',
        [`${analytics.PARAMETER.NUMBER_OF_PAUSE}`]: '',
        [`${analytics.PARAMETER.NUMBER_OF_RESUME}`]: '',
        [`${analytics.PARAMETER.VIDEO_QUALITY}`]: '',
        [`${analytics.PARAMETER.SEEK_BAR_PROGRESS}`]: '',
    }
    if (isForDeepLink) {
        return { ...commonData, ...deeplinkReqData }
    } else {
        return commonData;
    }
};

export const getCommonAnalyticsAttributes = (eventName, props, state, isApple = false) => {
    let { meta, meta: { taShowType = '' } = {} } = props;
    const reduxState = store.getState();
    const currentSubscription = reduxState?.subscriptionDetails?.currentSubscription?.data;
    const location = props?.location || props?.history?.location || {};
    const { mixpanelData: { configType, conPosition, sectionType } } = state;
    let mixpanelData = getAnalyticsData(MIXPANEL, isApple, props, state);
    let moengageData = getAnalyticsData(MOENGAGE, isApple, props, state);
    let isFreeContent = isFreeContentMixpanel(meta);
    let noSource = state?.mixpanelData?.source !== MIXPANEL.VALUE.OTHERS && state?.mixpanelData?.source !== "";
    let isLiveAutoPlay = get(location.state, "isLiveAutoPlay"),
        isLiveContent = [SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(get(location.state, "sectionSource")) ||
            [SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(get(location.state, "selectedContentSectionSource")),
        isWatchList = location?.state?.sectionSource === SECTION_SOURCE.WATCHLIST;

    let data = {
        [`${MIXPANEL.PARAMETER.SOURCE}`]: location?.state?.source === MIXPANEL.VALUE.WATCHLIST ? MIXPANEL.VALUE.BINGELIST_PAGE : state.mixpanelData.source || '',
        // [`${MIXPANEL.PARAMETER.ORIGIN}`]: state.mixpanelData.origin || '',
        [`${MIXPANEL.PARAMETER.CONTENT_LANGUAGE}`]: getContentLanguage(props?.meta?.audio) || '',
        [`${MIXPANEL.PARAMETER.PACK_NAME}`]: currentSubscription?.productName,
        [`${MIXPANEL.PARAMETER.PACK_PRICE}`]: currentSubscription?.amountValue,
        [`${MIXPANEL.PARAMETER.RELEASE_YEAR}`]: meta?.releaseYear || "",
        [`${MIXPANEL.PARAMETER.CONTENT_GENRE}`]: meta?.genre?.join() || meta?.genres?.join() || '',
        [`${MIXPANEL.PARAMETER.CONTENT_GENRE_PRIMARY}`]: meta?.genre?.[0] || meta?.genres?.[0] || "",
        [`${MIXPANEL.PARAMETER.CONTENT_LANGUAGE_PRIMARY}`]: getPrimaryLanguage(meta?.audio) || "",
        [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: isWatchList ? MIXPANEL.VALUE.WATCHLIST : location?.state?.source, //: getAnalyticsSource(props.location.pathname);
        [`${MIXPANEL.PARAMETER.CONTENT_RATING}`]: meta?.rating,
        [`${MIXPANEL.PARAMETER.FREE_CONTENT}`]: isFreeContent,
        [`${MIXPANEL.PARAMETER.DEVICE_TYPE}`]: MIXPANEL.VALUE.WEB,
        [`${MIXPANEL.PARAMETER.ACTOR}`]: meta?.actor?.join() || "",
        [`${MIXPANEL.PARAMETER.PACK_TYPE}`]: currentSubscription?.subscriptionPackType,
        [`${MIXPANEL.PARAMETER.SEARCH_KEYWORD}`]: location?.state?.searchValue || '',
        [`${MIXPANEL.PARAMETER.SEARCH_TYPE}`]: location?.state?.suggestorType || ''
    };

    if (eventName !== MIXPANEL.EVENT.HERO_BANNER_CLICKS) {
        data[`${MIXPANEL.PARAMETER.RAIL_POSITION}`] = eventName === MIXPANEL.EVENT.CONTENT_CLICK && noSource ? (state.mixpanelData.railPosition > 0 ? state.mixpanelData.railPosition - 1 : 0) : state.mixpanelData.railPosition || "";
        data[`${MIXPANEL.PARAMETER.RAIL_CATEGORY}`] = getAnalyticsRailCategory(location?.state?.sectionSource, MIXPANEL) || "";
        data[`${MIXPANEL.PARAMETER.RAIL}`] = state.mixpanelData.railTitle || '';
        data[`${MIXPANEL.PARAMETER.RAIL_TYPE}`] = isWatchList ? MIXPANEL.VALUE.BINGE_LIST : configType?.toUpperCase() || "";
        data[`${MIXPANEL.PARAMETER.RAIL_CATEGORY}`] = getAnalyticsRailCategoryRegular(sectionType, MIXPANEL) || "";
        data[`${MIXPANEL.PARAMETER.CONTENT_POSITION}`] = conPosition || "";
        mixpanelData[`${MIXPANEL.PARAMETER.RAIL_CATEGORY}`] = getAnalyticsRailCategoryRegular(sectionType, MIXPANEL) || "";
    }
    if (eventName === MIXPANEL.EVENT.PLAY_CONTENT) {
        data[`${MIXPANEL.PARAMETER.START_TIME}`] = "";
        data[`${MIXPANEL.PARAMETER.STOP_TIME}`] = "";

        if (meta?.provider?.toLowerCase() === PROVIDER_NAME.ZEE5 && props?.tag !== '') {
            data[`${MIXPANEL.PARAMETER.ZEE5TAG}`] = props?.tag;
        }
        if (meta?.provider?.toLowerCase() === PROVIDER_NAME.PRIME) {
            data = { ...data, ...primeMixpanelParameter() }
        }

        let taUseCaseData = setTaUseCaseId(location, taShowType);
        data = { ...data, ...taUseCaseData };

    }

    if (eventName === MIXPANEL.EVENT.VIEW_CONTENT_DETAIL) {
        const nonLivePiPageCta =  get(reduxState.PIDetails, 'nonLivePiPageCta.data.piPageVerbiages');
        isUserloggedIn() && (data[MIXPANEL.PARAMETER.CTA_TYPE] = nonLivePiPageCta?.header);
    }
    // if(meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE){
    //     data[`${MIXPANEL.PARAMETER.APPLE_COUPON_STATUS}`] = checkAppleStatus(currentSubscription, meta);
    //     data = {...data, ...appleRedemptionClaimedDate()}
    // }

    if (eventName === MIXPANEL.EVENT.VIEW_CONTENT_DETAIL && meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE) {
        let value, { activePlan, subscribed } = getUserPackInfo({ currentSubscription, meta }),
        { appleStatus } = appleStatusJouney();
        if(activePlan && subscribed){
            if (appleStatus === APPLE_REDEMPTION_STATUS.ENTITLED) {
                value = MIXPANEL.VALUE.APPLE_NOT_CLAIMED;
            }  else if (appleStatus === APPLE_REDEMPTION_STATUS.ACTIVATED) {
                value = MIXPANEL.VALUE.DEFAULT;
            } else {
                value = "";
            }
        }
        let piPageName = { [MIXPANEL.PARAMETER.PI_PAGE_NAME]: value};
        data = {...data , ...(!isEmpty(value) && piPageName)}
    }

    if ([MIXPANEL.EVENT.ACTIVATE_APPLE_TV_SUBSCRIPTION_CLICK].includes(eventName)) {
        data[`${MIXPANEL.PARAMETER.VIA_POPUP}`] = MIXPANEL.VALUE.NO;
    }

    if ([MIXPANEL.EVENT.CONTENT_CLICK, MIXPANEL.EVENT.VIEW_CONTENT_DETAIL].includes(eventName) && isLiveContent) {
        data[`${MIXPANEL.PARAMETER.PI_SKIP}`] = isLiveAutoPlay ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO
    }
    if (meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE) {
        data[`${MIXPANEL.PARAMETER.APPLE_COUPON_STATUS}`] = checkAppleStatus(currentSubscription, meta);
        data = { ...data, ...appleRedemptionClaimedDate() }
    }

    mixpanelData = { ...mixpanelData, ...data };
    moengageData = { ...moengageData, ...data };
    return { mixpanelData, moengageData };
};

export const getTAUseCaseId = (location, taShowType) => {
    let { refUseCase = '',
        taRecommendedRail = false,
        source = '',
        prevPath = '',
    } = location?.state || {};
    let refUseCaseId = getRefUseCase(refUseCase, taRecommendedRail, taShowType),
        prevPathName = prevPath?.split("/"),
        { isCategoriesPage, isPartnerPage } = isPartnerCategoryBrowseSearchPage(prevPathName);

    if (source === MIXPANEL.VALUE.TV_SHOWS) {
        source = MIXPANEL.VALUE.SHOWS;
    }

    let screenName = getKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE),
        data = GET_TA_REF_ID.find(i => i.SCREEN_NAME === screenName),
        taUseCaseId = data?.TA_VALUE || '';

    if (screenName === JOURNEY_INITIATED_SCREEN.HERO_BANNER_TA) {
        if (isCategoriesPage || isPartnerPage) {
            taUseCaseId = `${data.TA_VALUE}`;
        } else {
            taUseCaseId = `${data.TA_VALUE} ${source}`;
        }
    }

    if ([JOURNEY_INITIATED_SCREEN.RAIL_TA, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION_RAIL_TA].includes(screenName)) {
        taUseCaseId = refUseCaseId || data.TA_VALUE;
    }

    return taUseCaseId;
};


export const apiCall = async (id, contentType, obj) => {
    let { fetchPIData, showMainLoader, hideMainLoader, fetchContinueWatchingDetails, history, location, configResponse, currentSubscription, fetchPiPageCta } = obj.props;
    showMainLoader();
    await fetchPIData(id, contentType);
    history?.replace({ pathname: location?.pathname, search: location?.search, state: { ...location?.state, masterGenre: obj?.props?.meta?.masterGenre } });
    isUserloggedIn() && await fetchPiPageCta(obj?.props?.meta?.provider, obj?.props?.meta?.partnerId );

    /** ------ if contentType is TV_SHOW or any series episode then we need parent id and content type
     *  because fetchContinueWatchingDetails will give data of last-played content of passed ID and in case of
     *  TV_SHOW or any series episode we need data of their parent i.e BRAND to show correct information
     *  on play resume button and isFavourite star icon 
     *  If tv show is opened then tv show rel;ated last watch dxata will be fetched and shown
     * ------ **/
    let { updatedId, updatedContentType } = getParentContentData(obj.props.meta);
    if ([CONTENTTYPE.TYPE_TV_SHOWS, CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL].includes(obj.props.meta?.contentType)) {
        updatedId = obj.props.meta?.vodId;
        updatedContentType = obj.props.meta?.contentType;
    }
    !obj?.state?.recommended && await fetchContinueWatchingDetails(updatedId, updatedContentType, obj.props.meta?.partnerId);

    if (obj?.props?.meta?.provider?.toLowerCase() === "prime" && isUserloggedIn() && checkPartnerSubscribed(currentSubscription, obj.props.meta?.partnerId, obj.props.meta?.provider)) {
        let isClaimClcik = getKey(LOCALSTORAGE.ACTIVATE_PRIME_BUTTON_CLICK);
        let apvDetails = currentSubscription?.apvDetails;
        apvDetails && obj.setState({
            isPrimeNudge: apvDetails?.primeNudge,
        })
        if ((apvDetails?.primePackStatus?.toLowerCase() !== PRIME_ENTITLEMENT_STATUS.ACTIVATED || isClaimClcik)) {
            let status = await store.dispatch(getPrimeEntitlementDetails())
            if (isAmazonClaimClick(status?.data?.entitlement_status)) {
                showAmazonActivityToast(get(configResponse.config, 'primeContentVerbiages.primeActivationVerbiage', MESSAGE.AMAZON_ACTIVATION_TEXT));
                deleteKey(LOCALSTORAGE.ACTIVATE_PRIME_BUTTON_CLICK);
                deleteKey(LOCALSTORAGE.PRIME_LAUNCH_FREQUENCY)
                deleteKey(LOCALSTORAGE.PRIME_PERIODIC_FREQUENCY)
                setKey(LOCALSTORAGE.SHOW_AMAZON_TOAST, true)
            }
        }
    }
    if(obj?.props?.meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE && isUserloggedIn() && checkPartnerSubscribed(currentSubscription, obj.props.meta?.partnerId, obj.props.meta?.provider)){
        let {appleStatus, journey} = appleStatusJouney();
        if ((appleStatus !== APPLE_REDEMPTION_STATUS.ACTIVATED)) {
            await store.dispatch(getPartnerStatus())
        }
    }
    if (obj.props.meta) {
        let runtimePlaybackURLGenerationRequired = get(obj.props.meta, "runtimePlaybackURLGenerationRequired");
        const { meta: { taShowType, vodId }, detail } = obj.props;
        let data = taShowType.split('-');
        let content = data[1];
        let updatedId = getTAUpdatedId(id, contentType, vodId);
        if (detail?.contractName !== CONTRACT.RENTAL && obj.props?.meta?.contentType !== CONTENTTYPE.TYPE_WEB_SHORTS) {
            obj?.state?.recommended && await obj?.setState({ taRecommended: true })
            await fetchTARecommendedData(updatedId, content, obj.props, obj);
            hideMainLoader();
        } else {
            obj?.state?.recommended && await obj?.setState({ vrRecommended: true })
            await fetchVrRecommendedRail({ seeAll: false }, obj.props);
            hideMainLoader();
        }

        !obj?.state?.recommended && trackEventAnalytics(obj);
        runtimePlaybackURLGenerationRequired && handleFancodeRedirection(obj.props.meta);
    } else {
        hideMainLoader();
    }
}

export const getTAUpdatedId = (id, contentType, vodId) => {
    let updatedId = id;
    if ([CONTENTTYPE.TYPE_BRAND, CONTENTTYPE.TYPE_BRAND_CHILD, CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL,
    CONTENTTYPE.TYPE_SERIES, CONTENTTYPE.TYPE_SERIES_CHILD, CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL,
    CONTENTTYPE.TYPE_TV_SHOWS, CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL].includes(contentType)) {
        updatedId = vodId;
    }
    return updatedId;
}

export const fetchTARecommendedData = async (id, contentType, props, obj) => {
    const { configResponse, meta } = props;
    let taRelatedRail = get(configResponse, 'config.taRelatedRail');
    let data = taRelatedRail?.filter(item => item.contentType === contentType);
    let useCase = data?.[0]?.useCase;
    let layout = queryString.parse(props.location.search)['?layout'];
    let taFallBackUseCase = data?.[0]?.fallbackUseCase;

    let result = {
        contentType: contentType,
        placeholder: useCase,
        id: id,
        layout: layout || LAYOUT_TYPE.LANDSCAPE,
        provider: meta?.provider,
        max: TA_MAX_CONTENT.TA_MAX,
        genres: meta?.genre.join(","),
        taFallBackUseCase: taFallBackUseCase,
        taFallback: false,
        masterGenre: meta?.masterGenre?.join(",")
    },
        vrRecommendedData = { seeAll: false };

    await getTaRailData(data, result, obj, props, vrRecommendedData);
}

export const getTaRailData = async (data, result, obj, props, vrRecommendedData) => {
    const { getTARecommendationRail } = props;
    data && await getTARecommendationRail(result, vrRecommendedData.seeAll, vrRecommendedData.seeAll).catch(async () => {
        await taFallbackRail(props, obj, result, vrRecommendedData);
    });

    let state = store.getState()
    let taRecommendationList = get(state.PIDetails, 'taRecommendation.data.contentList');
    let taRecommendationCode = get(state.PIDetails, 'taRecommendation.code');

    if (taRecommendationCode === 0) {
        if (taRecommendationList?.length === 0) {
            await taFallbackRail(props, obj, result, vrRecommendedData);
        } else {
            setIsTaRecommendedRail(obj);
        }
    } else {
        await taFallbackRail(props, obj, result, vrRecommendedData);
    }
}

export const taFallbackRail = async (props, obj, result, vrRecommendedData) => {
    const { getTARecommendationRail } = props;
    let payload = result;
    payload.placeholder = result.taFallBackUseCase;
    payload.taFallback = true;
    await getTARecommendationRail(payload).then(async () => {
        let state = store.getState(),
            taFallbackCode = get(state.PIDetails, 'taRecommendation.code'),
            taFallbackList = get(state.PIDetails, 'taRecommendation.data');

        if (taFallbackCode === 0) {
            if (taFallbackList.contentList.length === 0) {
                await fetchVrRecommendedRail(vrRecommendedData, props, obj);
            } else {
                setIsTaRecommendedRail(obj);
            }
        } else {
            await fetchVrRecommendedRail(vrRecommendedData, props, obj);
        }
    }).catch(async () => {
        await fetchVrRecommendedRail(vrRecommendedData, props, obj);
    });
};

export const fetchVrRecommendedRail = async (recommendedData, props, obj) => {
    let data = recommendedData;
    if (!recommendedData?.seeAll || (obj?.state?.recommended && isEmpty(props?.location?.state))) {
        let { updatedId, updatedContentType } = getParentContentData(props.meta);
        data = {
            seeAll: obj?.state?.recommended && isEmpty(props?.location?.state) ? true : false,
            id: updatedId,
            contentType: updatedContentType,
            from: 0,
            max: 10,
            preferredLanguages: 'English',
        };
    };
    obj?.state?.recommended && await obj?.setState({ taRecommended: false, vrRecommended: true })

    await props.fetchPIRecommendedData(data, data.seeAll);
}

export const setIsTaRecommendedRail = (obj) => {
    !isEmpty(obj) && obj.setState({ taRecommendedRail: true });
};

export const trackEventAnalytics = (obj, isLiveAutoPlay = false, livePropsData = {}) => {
    const { location, meta, meta: { taShowType = '' } = {} } = obj.props;
    const { railTitle = '', source = '', sectionSource = '' } = location?.state || {};

    let value = '', updatedAnalyticsData = {};
    let isFreeContent = isFreeContentMixpanel(meta);
    if ([SECTION_SOURCE.EDITORIAL, SECTION_SOURCE.TVOD, SECTION_SOURCE.CONTINUE_WATCHING].includes(sectionSource)) {
        value = MIXPANEL.VALUE.EDITORIAL;
    } else if (sectionSource === SECTION_SOURCE.RECOMMENDATION) {
        value = MIXPANEL.VALUE.RECOMMENDATION;
    } else if ([SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.SEARCH].includes(sectionSource)) {
        value = MIXPANEL.VALUE.SEARCH;
    }
    appsFlyerConfig.trackEvent(APPSFLYER.EVENT.CONTENT_DETAIL, {
        [APPSFLYER.PARAMETER.CONTENT_TITLE]: getTitleAndDesc(meta, meta?.contentType),
        [APPSFLYER.PARAMETER.CONTENT_TYPE]: getAnalyticsContentType(meta?.contentType, APPSFLYER),
        [APPSFLYER.PARAMETER.FREE_CONTENT]: isFreeContent,
        [APPSFLYER.PARAMETER.SOURCE]: source,
        [APPSFLYER.PARAMETER.PARTNER_NAME]: meta?.provider,
        [APPSFLYER.PARAMETER.CONTENT_LANGUAGE]: getContentLanguage(meta?.audio),
    });

    obj.setState(
        {
            mixpanelData: {
                railTitle: railTitle,
                source: source,
                origin: value,
                conPosition: get(location.state, "conPosition"),
                railPosition: setRailPosition(obj.props),
                sectionSource: get(location.state, "sectionSource"),
                configType: get(location.state, "configType"),
                contentSectionSource: get(location.state, "contentSectionSource"),
                sectionType: get(location.state, "sectionType"),
            },
        },
        () => {
            if (obj.props?.location?.state?.isFromHeroBanner && !obj.props.location?.state?.isFromPlayNowButton) {
                const sectionType = get(location.state, "sectionType")
                updatedAnalyticsData = getCommonAnalyticsAttributes(MIXPANEL.EVENT.HERO_BANNER_CLICKS, obj.props, obj.state)
                updatedAnalyticsData.mixpanelData[MIXPANEL.PARAMETER.TIMESTAMP] = moment().valueOf();
                updatedAnalyticsData.mixpanelData[MIXPANEL.PARAMETER.HERO_BANNER_NUMBER] = sectionType === SECTION_TYPE.HERO_BANNER ? Number(obj?.props?.location?.state?.conPosition) + 1 : "";
                updatedAnalyticsData.mixpanelData[MIXPANEL.PARAMETER.HERO_BANNER_ORIENTATION] = MIXPANEL.VALUE.LANDSCAPE;
                mixPanelConfig.trackEvent(MIXPANEL.EVENT.HERO_BANNER_CLICKS, updatedAnalyticsData.mixpanelData);
            } else if (obj.props?.location?.state?.isFromHeroBanner && obj.props.location?.state?.isFromPlayNowButton) {
                let playNowMixpanel = {}
                const sectionType = get(location.state, "sectionType")
                playNowMixpanel[MIXPANEL.PARAMETER.HERO_BANNER_NUMBER] = sectionType === SECTION_TYPE.HERO_BANNER ? Number(obj?.props?.location?.state?.conPosition) + 1 : "";
                playNowMixpanel[MIXPANEL.PARAMETER.HERO_BANNER_ORIENTATION] = MIXPANEL.VALUE.LANDSCAPE;
                playNowMixpanel[`${MIXPANEL.PARAMETER.PAGE_NAME}`] = obj.props?.location?.state?.source;
                playNowMixpanel[MIXPANEL.PARAMETER.CONTENT_PARENT_TITLE] = getTitleAndDesc(meta, meta?.parentContentType, false, true);
                playNowMixpanel[MIXPANEL.PARAMETER.CONTENT_TITLE] = get(location.state, "title");
                mixPanelConfig.trackEvent(MIXPANEL.EVENT.HEROBANNER_PLAYNOW, playNowMixpanel);

            }

            let data = setTaUseCaseId(location, taShowType);

            if (isLiveAutoPlay) {
                obj.props = livePropsData;
            }

            updatedAnalyticsData = getCommonAnalyticsAttributes(MIXPANEL.EVENT.CONTENT_CLICK, obj.props, obj.state);
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_CLICK, { ...updatedAnalyticsData.mixpanelData, ...data });

            updatedAnalyticsData = getCommonAnalyticsAttributes(MIXPANEL.EVENT.VIEW_CONTENT_DETAIL, obj.props, obj.state);
            !isLiveAutoPlay && mixPanelConfig.trackEvent(MIXPANEL.EVENT.VIEW_CONTENT_DETAIL, { ...updatedAnalyticsData.mixpanelData, ...data });
        }
    );
};


export const setRailPosition = (props) => {
    const { location } = props;
    const source = get(location.state, 'source');
    const railPosition = get(location.state, 'railPosition');

    if ([MIXPANEL.VALUE.HOME, MIXPANEL.VALUE.MOVIES, MIXPANEL.VALUE.TV_SHOWS, MIXPANEL.VALUE.SPORTS, MIXPANEL.VALUE.SEE_ALL].includes(source) &&
        !(railPosition === undefined || railPosition === null)) {
        return parseInt(railPosition) + 1;
    }
    return railPosition;
}

export const trackTrailerEvent = async (props, state) => {
    const { meta, detail, location, currentSubscription } = props;
    const { source, railPosition, conPosition: contentPosition, sectionSource, sectionType, configType } = location.state || {};
    let mixpanelData = getAnalyticsData(MIXPANEL, false, props, state);
    let partnerPlayable = isUserloggedIn() && await checkPartnerPlayable(meta?.partnerId, meta?.provider);
    let isFreeContent = isFreeContentMixpanel(meta);
    let { updatedContentType } = getParentContentData(props.meta);

    let partnerInfo = JSON.parse(getKey(LOCALSTORAGE.PARTNER_INFO)) || [];

    const contentAuth = (isFreeContentEvent(detail.contractName, partnerInfo, meta.partnerId, currentSubscription?.subscriptionStatus) ||
        (meta?.partnerSubscriptionType?.toLowerCase() === PARTNER_SUBSCRIPTION_TYPE.PREMIUM.toLowerCase()))

    mixpanelData[`${MIXPANEL.PARAMETER.RAIL_TYPE}`] = configType?.toUpperCase() || "";
    mixpanelData[`${MIXPANEL.PARAMETER.RAIL_CATEGORY}`] = getAnalyticsRailCategoryRegular(sectionType, MIXPANEL);
    mixpanelData[`${MIXPANEL.PARAMETER.SUBSCRIBED}`] = partnerPlayable ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO;
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_LANGUAGE}`] = getContentLanguage(meta?.audio) || "";
    mixpanelData[`${MIXPANEL.PARAMETER.FREE_CONTENT}`] = isFreeContent;
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_RATING}`] = meta?.rating;
    mixpanelData[`${MIXPANEL.PARAMETER.RAIL}`] = state.mixpanelData.railTitle || '';
    mixpanelData[`${MIXPANEL.PARAMETER.RAIL_POSITION}`] = railPosition || "";
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_GENRE_PRIMARY}`] = meta?.genre?.[0] || meta?.genres?.[0] || "";
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_LANGUAGE_PRIMARY}`] = getPrimaryLanguage(meta?.audio) || "";
    mixpanelData[`${MIXPANEL.PARAMETER.RELEASE_YEAR}`] = meta?.releaseYear || "";
    mixpanelData[`${MIXPANEL.PARAMETER.DEVICE_TYPE}`] = MIXPANEL.VALUE.WEB;
    mixpanelData[`${MIXPANEL.PARAMETER.ACTOR}`] = meta?.actor.join() || '';
    mixpanelData[`${MIXPANEL.PARAMETER.PACK_NAME}`] = currentSubscription?.productName;
    mixpanelData[`${MIXPANEL.PARAMETER.PACK_PRICE}`] = currentSubscription?.amountValue;
    mixpanelData[`${MIXPANEL.PARAMETER.SOURCE}`] = source;
    mixpanelData[`${MIXPANEL.PARAMETER.RAIL_TYPE}`] = sectionSource?.toUpperCase();
    mixpanelData[`${MIXPANEL.PARAMETER.RAIL_CATEGORY}`] = getAnalyticsRailCategory(sectionType, MIXPANEL);
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_AUTH}`] = contentAuth ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO;
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_CATEGORY}`] = updatedContentType;
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_POSITION}`] = contentPosition;
    //passed parent content type so that when we fetch title we get title of the parent always
    mixpanelData[`${MIXPANEL.PARAMETER.CONTENT_PARENT_TITLE}`] = getTitleAndDesc(meta, meta?.parentContentType, false, true);
    mixpanelData[`${MIXPANEL.PARAMETER.LIVE_CONTENT}`] = MIXPANEL.VALUE.NO;
    mixpanelData[`${MIXPANEL.PARAMETER.PAGE_NAME}`] = getAnalyticsSource(props.location.pathname);
    mixpanelData[`${MIXPANEL.PARAMETER.PACK_TYPE}`] = currentSubscription?.subscriptionPackType;
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.PLAY_TRAILER, mixpanelData);
}

export const getCircularLogo = (meta) => {
    let imageUrl = '';
    imageUrl = meta ? providerImage(meta?.provider, LAYOUT_TYPE.CIRCULAR) : imageUrl;

    return imageUrl;
};



/**
 * @function getParentContentData - will return id and type of parent of particular contentType
 * @param data - expected meta object from detail API
 * @returns object with {updatedId, updatedContentType}
 */
export const getParentContentData = (data) => {
    let updatedContentType = get(data, "parentContentType"),
        updatedId;

    if ([CONTENTTYPE.TYPE_BRAND, CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL, CONTENTTYPE.TYPE_BRAND_CHILD].includes(updatedContentType)
    ) {
        updatedId = data.brandId;
    } else if (
        [CONTENTTYPE.TYPE_SERIES, CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL, CONTENTTYPE.TYPE_SERIES_CHILD].includes(updatedContentType)
    ) {
        updatedId = data.seriesId;
    } else {
        updatedContentType = get(data, "contentType");
        updatedId = get(data, "vodId");
    }

    return { updatedId, updatedContentType };
};

export const checkForNonIntegratedPartner = (provider, isTrailer = false) => {
    const providerLowerCase = provider.toLowerCase();
    let isHungamaTrailer = providerLowerCase === PROVIDER_NAME.HUNGAMA && isTrailer;
    let ALL_INTEGRATED_PARTNER_LIST = getAllIntegratedPartnerList();
    if (ALL_INTEGRATED_PARTNER_LIST.includes(providerLowerCase) || isHungamaTrailer) {
        return true;
    }
    else {
        store.dispatch(
            openPopup(MODALS.ALERT_MODAL, {
                modalClass: 'alert-modal',
                instructions: 'Partner playback integration is pending',
                primaryButtonText: 'Ok',
                closeModal: true,
                hideCloseIcon: true,
            })
        );
        return false;
    }
};

export const fireProductLandingCtasEvent = (data, buttonName, episodeNumber) => {
    let title = data?.contentType === CONTENTTYPE.TYPE_MOVIES ? data?.vodTitle : (data?.brandTitle || data?.seriesTitle || data?.vodTitle || data?.title)
    dataLayerConfig.trackEvent(DATALAYER.EVENT.PRODUCT_LANDING_CTAS, {
        [DATALAYER.PARAMETER.CONTENT_TITLE]: title,
        [DATALAYER.PARAMETER.CONTENT_TYPE]: data?.contentType,
        [DATALAYER.PARAMETER.BUTTON_NAME]: buttonName,
        [DATALAYER.PARAMETER.EPISODE_NUMBER]: episodeNumber,
        [DATALAYER.PARAMETER.MORE_BUTTON_NAME]: buttonName,
    })
};

export const isLivePlayable = (channelId) => {
    const storeState = store.getState();
    const currentSubscription = get(storeState.subscriptionDetails, 'currentSubscription.data');

    return !isEmpty(currentSubscription) && currentSubscription?.liveChannelIds && currentSubscription?.liveChannelIds.includes(channelId)
};


export const getApplePlayCTA = (data, playBtn) => {
    let playCTA = getVerbiages(CATEGORY_NAME.APPLE_PLAY_CTA)?.others?.message,
        subscribeCTA = getVerbiages(CATEGORY_NAME.APPLE_SUSBCRIBE_CTA)?.others?.message,
        upgradeCTA = getVerbiages(CATEGORY_NAME.APPLE_UPGRADE_CTA)?.others?.message,
        {appleStatus, journey} = appleStatusJouney();

    if (!isUserloggedIn()) {
        return playCTA || PLAY_ACTION.PLAY_NOW;
    } else {
        let { subscribed, noPack, expired, activePlan, lowerPack } = getUserPackInfo(data),
        isShowPlayCta = APPLE_REDEMPTION_STATUS.ENTITLED === appleStatus && isEmpty(journey); // jouney check is integrated for the flow of migration and renew

        if(data?.meta?.isTrailerFromCMS){
            return playBtn
        } else if (noPack || expired) {
            return subscribeCTA || PLAY_ACTION.SUBSCRIBE_TO_WATCH;
        } else if (lowerPack) {
            return upgradeCTA || PLAY_ACTION.UPGRADE_TO_WATCH;
        } else if (activePlan && subscribed) {
            if (isShowPlayCta) {
                return '';
            } else {
                return playBtn;
            }
        }
    }
}

export const knowMoreClick = (props) => {
    let openKnowMorePopup = props.openPopup;

    appleHelpClickedEvent(openKnowMorePopup);

    if (openKnowMorePopup) {
        appleActivateVideoPopup(props.commonProps, true);
    } else {
        playAppleHelpVideo(props);
    }
};

export const playAppleHelpVideo = (props) => {
    let id = props?.commonProps?.match?.params?.id || "18869",
        contentType = props?.commonProps?.match?.params?.contentType || CONTENTTYPE.TYPE_BRAND;

    store.dispatch(closePopup());

    window.open(`/${ROUTE_URL.PLAYER}/${contentType}/${id}?appleHelpVideo=true`, '_blank');
}

export const appleHelpClickedEvent = (openKnowMorePopup) => {
    let source;
    if (openKnowMorePopup) {
        source = MIXPANEL.VALUE.PI_PAGE_LOWERCASE;
    } else {
        source = MIXPANEL.VALUE.ACTIVATION_POPUP;
    }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.HELP_CLICKED, {
        [MIXPANEL.PARAMETER.SOURCE]: source,
        [MIXPANEL.PARAMETER.HELP_TYPE]: MIXPANEL.VALUE.APPLE_ACTIVATION_VIDEO,
    });

    setKey(LOCALSTORAGE.APPLE_HELP_CLICKED_SOURCE, source);
}

export const sendDistroTracking = (eventName, providerContentId = '', pageName = '', errorMsg = '') => {
    let trackUrlParams = getDistroTrackParams(eventName, pageName, errorMsg, providerContentId),
        trackUrl = new URL(DISTRO_TRACKING_URL),
        trackUrlPath = trackUrl.origin + trackUrl.pathname;
    let resultUrl = `${trackUrlPath}?${trackUrlParams}`;
    console.log('track url', resultUrl);
    store.dispatch(sendDistroTrackEvent(resultUrl));
};

/**
 * 
 * @param {*} eventName 
 * @param {*} pageName 
 * @param {*} errorMsg 
 * @param {*} providerContentId 
 * @returns Tracking pixel url for distro with macros replcaed
 */
export const getDistroTrackParams = (eventName, pageName, errorMsg, providerContentId) => {
    let url = getSearchParamsAsObject(DISTRO_TRACKING_URL),
        daiSessionId = getKey(LOCALSTORAGE.DAI_SESSION_ID) || '',
        daiDeviceId = getKey(LOCALSTORAGE.DAI_DEVICE_ID) || '',
        isFFEvent = eventName === DISTRO_EVENT_NAME.FF;

    const storeState = store.getState(),
        liveDetailData = get(storeState.PIDetails, 'liveDetail.data.detail'),
        liveMetaData = get(storeState.PIDetails, 'liveDetail.data.meta[0]'),
        livePlayBackEncryptedUrl = get(storeState.playerWatchlist, 'livePlayBackUrls');

    const environment = (process.env.NODE_ENV || 'development')?.toString()?.trim()?.toLowerCase();

    for (let [key, value] of Object.entries(url)) {
        const eventArray = DISTRO_TRACK_EVENT_ARRAY.map(a => ({ ...a }));
        let data = eventArray.find(i => i.TRACKING_KEY === value);

        if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.RANDOM) { // r
            data.TRACKING_VALUE = data?.TRACKING_VALUE();
        } else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.EVENT_NAME) { // e
            data.TRACKING_VALUE = eventName;
        } else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.DEVICE_ID) { //u
            data.TRACKING_VALUE = !isEmpty(daiDeviceId) ? daiDeviceId : data?.TRACKING_VALUE();
        } else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.DAI_SESSION_ID) { //i / V
            if (isFFEvent) {
                data.TRACKING_VALUE = DISTRO_EVENT_NAME.FF;
            } else {
                data.TRACKING_VALUE = daiSessionId;
            }
        }
        else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.DAI_ASSET_KEY) {// f
            if (isFFEvent) {
                data.TRACKING_VALUE = pageName;
            } else if (liveDetailData?.playbackType === LIVE_PLAYBACK_TYPE.DAI) {
                data.TRACKING_VALUE = liveDetailData.contentDai;
            } else if (liveDetailData?.playbackType === LIVE_PLAYBACK_TYPE.CUSTOM) {
                let playUrl = getDistroPlayUrlForNonDai();
                playUrl = encodeURIComponent(playUrl)
                data.TRACKING_VALUE = playUrl; //Send encoded play back url for non dai
            }
        }
        else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.CONTENT_PROVIDER_ID && !isFFEvent) { //p
            data.TRACKING_VALUE = providerContentId;
        }
        else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.SHOW_ID && !isFFEvent) { // show
            data.TRACKING_VALUE = liveMetaData?.showId;
        }
        else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.EPISODE_ID && !isFFEvent) { // ep
            data.TRACKING_VALUE = liveMetaData?.id;
        }
        else if (data?.TRACKING_KEY === DISTRO_TRACK_EVENT_KEY.APPNAME && environment === "production") { // m
            data.TRACKING_VALUE = DISTRO_MACRO_VALUES.APP_VALUE_PROD;
        }

        if (data) {
            value = data.TRACKING_VALUE;
        }
        url[key] = value;
    }
    if (eventName === DISTRO_EVENT_NAME.ERR) {
        url[DISTRO_TRACK_EVENT_KEY.MSG] = errorMsg;
    }
    console.log(`Distro tracking url for : ${liveDetailData?.playbackType}`, url,)
    return new URLSearchParams(url).toString();
};

export const trackContentPlayFailure = (history, isSeriesLA) => {
    const location = history?.location || {};
    let storeState = store.getState();
    const detail = get(storeState.PIDetails.data, 'meta'),
        playDetail = get(storeState.PIDetails.data, 'detail'),
        currentSubscription = get(storeState.subscriptionDetails, 'currentSubscription.data'),
        liveMetaData = get(storeState.PIDetails, 'liveDetail.data.meta[0]'),
        liveChannelMetaData = get(storeState.PIDetails, 'liveDetail.data.channelMeta'),
        checksomeMismatchVerbiages = getVerbiages(CATEGORY_NAME.CHECKSOME_MISMATCH),
        contentType = detail?.contentType;

    let isLiveContent = false;
    if (isContentLive(contentType)) {
        isLiveContent = true;
        detail = liveMetaData;
    }

    const railTitle = get(location, "state.railTitle");
    let title = contentType === CONTENTTYPE.TYPE_MOVIES ? detail?.vodTitle || detail?.title : (detail?.title || detail?.vodTitle || detail?.brandTitle);

    let actor = detail?.actor?.join() || '',
        source = get(location, 'state.source'),
        railPosition = get(location, "state.railPosition"),
        contentPosition = get(location, "state.conPosition"),
        { updatedContentType } = getParentContentData(detail),
        { configType, sectionType, contentSectionSource } = location?.state || {},
        partnerInfo = JSON.parse(getKey(LOCALSTORAGE.PARTNER_INFO)) || [],
        contentAuth = (isFreeContentEvent(playDetail?.contractName, partnerInfo, detail?.partnerId, currentSubscription?.subscriptionStatus)
            || (detail?.partnerSubscriptionType?.toLowerCase() === PARTNER_SUBSCRIPTION_TYPE.PREMIUM.toLowerCase())),
        isFreeContent = isFreeContentMixpanel(detail);
    let commonData = {
        [MIXPANEL.PARAMETER.CONTENT_GENRE]: detail?.genre.join(),
        [MIXPANEL.PARAMETER.CONTENT_LANGUAGE]: getContentLanguage(detail?.audio),
        [MIXPANEL.PARAMETER.RAIL]: railTitle || '',
        [MIXPANEL.PARAMETER.CONTENT_GENRE_PRIMARY]: detail?.genre[0] || "",
        [MIXPANEL.PARAMETER.CONTENT_LANGUAGE_PRIMARY]: getPrimaryLanguage(detail?.audio),
        [MIXPANEL.PARAMETER.RELEASE_YEAR]: detail?.releaseYear || "",
        [MIXPANEL.PARAMETER.DEVICE_TYPE]: MIXPANEL.VALUE.WEB,
        [MIXPANEL.PARAMETER.FREE_CONTENT]: isFreeContent,
        [MIXPANEL.PARAMETER.CONTENT_RATING]: isLiveContent ? detail?.rating : detail?.masterRating,
        [MIXPANEL.PARAMETER.PAGE_NAME]: location?.state?.source,
        [MIXPANEL.PARAMETER.CONTENT_TITLE]: isLiveContent ? liveChannelMetaData?.channelName : title,
        [MIXPANEL.PARAMETER.ACTOR]: actor,
        [MIXPANEL.PARAMETER.SOURCE]: source,
        [MIXPANEL.PARAMETER.CONTENT_PARTNER]: isLiveContent ? detail?.provider || MIXPANEL.VALUE.TATAPLAY : detail?.provider,
        [MIXPANEL.PARAMETER.RAIL_POSITION]: railPosition,
        [MIXPANEL.PARAMETER.PACK_PRICE]: currentSubscription?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
        [MIXPANEL.PARAMETER.RAIL_TYPE]: configType?.toUpperCase(),
        [MIXPANEL.PARAMETER.CONTENT_TYPE]: contentSectionSource?.toUpperCase(),
        [MIXPANEL.PARAMETER.RAIL_CATEGORY]: getAnalyticsRailCategoryRegular(sectionType, MIXPANEL),
        [MIXPANEL.PARAMETER.CONTENT_AUTH]: contentAuth ? MIXPANEL.VALUE.CAPITALIZED_YES : MIXPANEL.VALUE.CAPITALIZED_NO,
        [MIXPANEL.PARAMETER.CONTENT_CATEGORY]: updatedContentType,
        [MIXPANEL.PARAMETER.CONTENT_POSITION]: contentPosition,
        [MIXPANEL.PARAMETER.CONTENT_PARENT_TITLE]: isLiveContent ? liveChannelMetaData?.channelName : (detail?.brandTitle || detail?.vodTitle),
        [MIXPANEL.PARAMETER.AUTO_PLAYED]: isUserloggedIn() && isLiveContent ? MIXPANEL.VALUE.CAPITALIZED_YES : MIXPANEL.VALUE.CAPITALIZED_NO,
        [MIXPANEL.PARAMETER.LIVE_CONTENT]: getLiveContentValue(isLiveContent, detail),
        [MIXPANEL.PARAMETER.REASON]: get(checksomeMismatchVerbiages, "others.restrictPlayBackMixPanelReason"),
    }
    if(isSeriesLA){
        commonData[MIXPANEL.PARAMETER.REASON] = MIXPANEL.VALUE.SERIES_ID_NOT_FOUND;
        return commonData
    }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_PLAY_FAIL, commonData);
}

/**
 * 
 * @returns Playback url for non dai content with marcos replaced
 */
export const getDistroPlayUrlForNonDai = () => {
    const storeState = store.getState(),
        livePlayBackEncryptedUrl = get(storeState.playerWatchlist, 'livePlayBackUrls');
    //let playUrl = "https://stream-us-east-1.getpublica.com/playlist.m3u8?network_id=1962&live=1&avod=1&app_bundle=__APP_BUNDLE__&did=__DEVICE_ID__&app_category=__APP_CATEGORY__&app_domain=__APP_DOMAIN__&app_name=business-rockstars&app_store_url=__STORE_URL__&app_version=__APP_VERSION__&hls_marker=1&cb=__CACHE_BUSTER__&ip=__CLIENT_IP__&ua=__USER_AGENT__&player_height=1080&player_width=1920&is_lat=__LIMIT_AD_TRACKING__&ads.islive=1&ads.streamtype=live"
    let playUrl = decryptUrl(livePlayBackEncryptedUrl?.playUrl),
        playUrlParams = getDistroTvPlaybackUrl(playUrl);
    playUrl = new URL(playUrl)
    const playUrlPath = playUrl?.origin + playUrl?.pathname;
    return `${playUrlPath}?${playUrlParams}`;
}


/**
 * 
 * @param {*} playUrl play url for non dai content
 * Replace macros in the url with the expected values
 * @returns 
 */
export const getDistroTvPlaybackUrl = (playUrl) => {
    let url = getSearchParamsAsObject(playUrl),
        daiDeviceId = getKey(LOCALSTORAGE.DAI_DEVICE_ID),
        uuid = generateUuidV4String();
    if (isEmpty(daiDeviceId)) {
        setKey(LOCALSTORAGE.DAI_DEVICE_ID, uuid);
        daiDeviceId = uuid;
    }
    for (let [key, value] of Object.entries(url)) {
        const distroArray = DISTRO_MACRO_ARRAY.map(a => ({ ...a }));
        let data = distroArray.find(i => i.MACRO_KEY === value);
        if ([DISTRO_MACRO_KEY.ENV_I, DISTRO_MACRO_KEY.ENV_U, DISTRO_MACRO_KEY.ADVERTISING_ID, DISTRO_MACRO_KEY.APP_DOMAIN, DISTRO_MACRO_KEY.CACHE_BUSTER, DISTRO_MACRO_KEY.PLAYBACK_ID, DISTRO_MACRO_KEY.PALN].includes(data?.MACRO_KEY)) {
            data.MACRO_VALUE = data.MACRO_VALUE();
        }
        if (data?.MACRO_KEY === DISTRO_MACRO_KEY.DEVICE_ID) {
            data.MACRO_VALUE = daiDeviceId;
        }
        if (data?.MACRO_KEY === DISTRO_MACRO_KEY.PAGEURL_ESC) {
            data.MACRO_VALUE = window.location.href;
        }
        if (data) {
            value = data.MACRO_VALUE
        }
        url[key] = value;
        console.log(key,":",value,'distro')
    }
    return new URLSearchParams(url).toString();
}

export const handleFancodeRedirection = (meta) => {
    let providerName = get(meta, 'provider');
    let { genericAuthType } = getGenericAuthType(providerName.toLowerCase());
    if ([PLAY_AUTH_TYPE.DRM_PLAYBACK, PLAY_AUTH_TYPE.DRM_LICENSED_TOKEN].includes(genericAuthType) && isUserloggedIn()) {
        let systemDetail = getSystemDetails();
        let deviceOs = systemDetail?.browser === BROWSER_TYPE.SAFARI ? GENERIC_DEVICE_OS.IOS : GENERIC_DEVICE_OS.ANDROID,
            userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};

        let payload = {
            partnerContentId: meta?.providerContentId,
            deviceOs: deviceOs,
            provider: providerName?.toUpperCase(),
            mobileNo: userInfo?.rmn,
        };

        let headerData = {
            drmAuthType: "NO"
        }

        store.dispatch(getGenericDrm(payload, headerData));
        store.dispatch(getCurrentSubscriptionMyplanVerbiages());
    }
}


export const getPiPagePageCtaText = (playMovieBtn, noneLivePiCtaText, meta, lastWatch) => {
    let piPageCta = {
        header: playMovieBtn || PLAY_ACTION.PLAY_NOW,
        subHeader: '',
        showCrown: false,
    };
    let partnerSubscriptionType = getPartnerSubscriptionType(meta, lastWatch);
    if(isNewPlayCtaJourney() && isUserloggedIn() && !isEmpty(noneLivePiCtaText?.piPageVerbiages) && !checkPartnerPlayable(meta?.partnerId, meta?.provider) && partnerSubscriptionType === PARTNER_SUBSCRIPTION_TYPE.PREMIUM ){
        piPageCta = noneLivePiCtaText?.piPageVerbiages
    }
    return piPageCta;
}


export const isTvShowContent = (contentType) => {
    const tvShowsItems = ['BRAND', 'TV_SHOWS', 'SERIES', 'SERIES_CHILD_LOCAL', 'BRAND_CHILD_LOCAL', 'CUSTOM_TV_SHOWS_DETAIL', 'CUSTOM_BRAND_DETAIL','CUSTOM_SERIES_DETAIL']
    return tvShowsItems?.includes(contentType)
}
