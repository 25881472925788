import React, { useRef, useState, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { get } from "lodash";

import { openPopup } from "@common/Modal/action";
import { MODALS } from "@common/Modal/constants";

import { CHATBOT_TYPE, TICKET_STATE } from "../../APIs/constants";
import CommentCard from "../CommentCard";
import ReopenTicket from "../ReopenTicket";
import ProgressStep from "../ProgressStep";
import { checkIfDateExceededExpectedDate } from "./TickeCardCommon";
import TicketFeedbackHC from "../TicketFeedbackHC";

const TicketBottomDetail = ({
  status,
  raisedDate,
  id,
  ticketCardData,
  isLandingPage,
  expectedResolutionDate,
  reCloseComments,
  chatbotType,
  closedDate
}, ref) => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(false);
  const [isTransition, setTransition] = useState(false);

  const hcFeedbackExpiryInDays = useSelector(state => get(state.headerDetails, "configResponse.data.config.helpCenterInfo.hcFeedbackExpiryInDays"));

  const listRef = useRef();

  const listExpand = async () => {
    setExpand(!expand);
    setTransition(false);
  };

  useImperativeHandle(ref, () => {
    return {
      ...ref?.current,
      isPrevExpand: expand,
      listRef: listRef.current,
      listExpand: (val) => setExpand(val),
      isTransition: () => setTransition(true),
      reOpenTicket: reOpenTicket
    }
  }, [expand])

  const showReOpenCTA = (raisedDate) => {
    const data = ticketCardData,
      originalDate = moment().format("lll"),
      formatedRaisedDate = moment.utc(raisedDate).format("lll"),
      remainingDays = moment(originalDate).diff(formatedRaisedDate, "days"),
      checkFalsy = remainingDays == 0 ? 1 : remainingDays,
      showReopen = checkFalsy <= 7 && Number(data.reOpenCounter) < 2 ? true : false;
    return showReopen;
  };

  const showTicketFeedbackForm = () => {
    const ticketClosedDate = moment.utc(closedDate).format("lll"),
      originalDate = moment().format("lll"),
      remainingDays = moment(originalDate).diff(ticketClosedDate, "days"),
      checkFalsy = remainingDays == 0 ? 1 : remainingDays,
      showForm = checkFalsy <= hcFeedbackExpiryInDays ? true : false;

    console.log("Ticket Feedback :", closedDate, remainingDays)

    return showForm;
  };

  const openReopenTicketPopup = () => {
    dispatch(openPopup(MODALS.COMMENT_MODAL, {
      modalClass: "comment-modal-popup reopen-modal",
      childComponent: (
        <ReopenTicket
          id={id}
          status={status}
          ticketCardData={ticketCardData}
          isLandingPage={isLandingPage}
        />
      ),
    }));
  };

  let isStatusReOpen = status?.toLowerCase() == TICKET_STATE.REOPEN,
    isStatusClose = status?.toLowerCase() == TICKET_STATE.CLOSE,
    isStatusOpen = status?.toLowerCase() == TICKET_STATE.OPEN,
    isTicketClosed = isStatusClose || status?.toLowerCase() === TICKET_STATE.CLOSED;

  let reOpenTicket = showReOpenCTA(raisedDate) && isStatusClose;
  let ticketFeedbackHC = isTicketClosed && showTicketFeedbackForm();
  let isTicketStatusOpenAndDateExpired = (isStatusOpen || isStatusReOpen) && checkIfDateExceededExpectedDate(expectedResolutionDate);

  return (
    <div className="request-view">
      <div
        className={`${expand ? `collapse-show ${isTransition ? 'unset-transition' : ''}` : `collapse-hide ${isTransition ? 'unset-transition' : ''}`}`}
        ref={listRef}
      >
        {chatbotType === CHATBOT_TYPE.ORISERVE && (
          <div className="sts-contr sts-comment ">
            <CommentCard ticketCardData={ticketCardData} />
          </div>
        )}
        {isLandingPage && (
          <div className={`${ticketFeedbackHC ? "ticket-status-wrapper" : ""}`}>
            <ProgressStep
              isStatusClose={isTicketClosed}
              isStatusOpen={isStatusOpen}
              isStatusReOpen={isStatusReOpen}
              raisedDate={raisedDate}
              expectedResolutionDate={expectedResolutionDate}
              reCloseComments={reCloseComments}
              ref={ref}
              status={status}
              isTicketStatusOpenAndDateExpired={isTicketStatusOpenAndDateExpired}
            />
            {ticketFeedbackHC && <TicketFeedbackHC ticketId={id} chatbotType={chatbotType} />}
          </div>
        )}
      </div>
      <div className={`cta-contr ${(isLandingPage && reOpenTicket) ? 'cta-reopen' : ''}`}>
        <p
          className="link-view"
          onClick={() => listExpand()}
        >
          {!expand ? "View Details" : "View Less"}
        </p>
        {reOpenTicket && (
          <a
            className="reopen-btn"
            onClick={() => {
              openReopenTicketPopup();
            }}
          >
            Re-open
          </a>
        )}
      </div>
    </div>
  )
}

export default React.forwardRef(TicketBottomDetail);