import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";

import Heading from "@common/Heading";
import { closePopup, openPopup } from "@common/Modal/action";
import { getDeviceId, getLayeredIcon, isMobile, getAnalyticsSource, safeNavigation, scrollToTop, isSubscriptionDiscount, getDeviceRemoveAnalytics, cloudinaryCarousalUrl } from "@utils/common";
import { MODALS } from "@common/Modal/constants";
import NoDataAvailable from "@common/NoDataAvailable";
import { withRouter } from "react-router";

import {
    getSubscriberDeviceList,
    deleteSubscriberDeviceList,
} from "./APIs/action";
import RadioButton from "../../common/RadioButton";
import "./style.scss";
import { DEVICE_TYPE, LOCALSTORAGE, MENU_LIST } from "@constants";
import { MESSAGES } from "@containers/DeviceManagement/constant";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import MOENGAGE from "@constants/moengage";
import moengageConfig from "@utils/moengage";
import { getKey } from "@utils/storage";
import { updateUser, onLoginSuccess, handleLoginClose, notNow, loginInNotSuccessFulToast, LoginSuccessToast } from "@containers/Login/LoginCommon";
import { URL } from "@utils/constants/routeConstants";
import { getSystemDetails } from "@utils/browserEnvironment";
import Button from "@common/Buttons";
import { hideFooter } from "@src/action";
import { toast } from "react-toastify";

class DeviceManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDevices:[]
        };
    }

    componentDidMount() {
        this.loadHandler();
        scrollToTop();
        isMobile.any() && (this.props.hideFooter(true));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.subscriberDeviceData !== this.props.subscriberDeviceData && this.state.selectedDevices.length >0){
            const updatedDevices = this.state.selectedDevices.filter(device =>
                this.props.subscriberDeviceList?.some(stateDevice => stateDevice.deviceNumber === device.deviceNumber)
            );            
          this.setState({ selectedDevices: updatedDevices})
        }
    }

    componentWillUnmount() {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LISTING_EXIT, {
            [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.DEVICE_MANAGEMENT,
        })
    }

    loadHandler = async () => {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        let { getSubscriberDeviceList, isBeforeLogin, location: { state }, history, pathState } = this.props;
        const checkBeforeLogin = isBeforeLogin || state?.isBeforeLogin;
        const payload = isMobile.any() ? state?.data : pathState?.data;
        await getSubscriberDeviceList(checkBeforeLogin, payload).then(data => {
            const deviceNames = data?.deviceList?.map(device => device.deviceName).join(',');
            // mixPanelConfig.trackEvent(MIXPANEL.EVENT.LIST_DEVICES);
            // mixPanelConfig.trackEvent(MIXPANEL.EVENT.LIST_DEVICES, {
            //     [MIXPANEL.PARAMETER.SID]: checkBeforeLogin ? payload?.subscriberId : userInfo.sId,
            // });
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_LISTING_VIEW, {
                [MIXPANEL.PARAMETER.DEVICE_LIST]: deviceNames,
                [MIXPANEL.PARAMETER.SOURCE]: getAnalyticsSource(this.props.location.pathname),
            })
            // moengageConfig.trackEvent(MOENGAGE.EVENT.LIST_DEVICES);
        }).catch(error => {
            if (location?.state?.isBeforeLogin) {
                notNow();
                safeNavigation(history, URL.DEFAULT);
            }
        })
    };

    deleteSubscriberDevice = async (deviceDetail) => {
        let { deleteSubscriberDeviceList, closePopup, isBeforeLogin, onDeviceRemoved, location, history, selectedPack, pathState } = this.props;
        const checkBeforeLogin = isMobile.any() ? location?.state?.isBeforeLogin : isBeforeLogin;
        const data = isMobile.any() ? location?.state?.data : pathState?.data;
        await deleteSubscriberDeviceList(deviceDetail, checkBeforeLogin, data).then(async (response) => {
            this.setState({selectedDevices:[]})
            if (response && response?.code === 0) {
                if (checkBeforeLogin) {
                    const source = isMobile.any() ? location?.state?.source : pathState?.source;
                    updateUser(data, onLoginSuccess, history, source, handleLoginClose, selectedPack)
                }
                else {
                    this.toastHandler(response?.message)
                    this.loadHandler();
                }
                
                mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_REMOVE_CONFIRM,
                    {
                        ...this.deviceConfirmData(MIXPANEL),
                        ...getDeviceRemoveAnalytics(response.data?.deviceId, deviceDetail),
                    });
                moengageConfig.trackEvent(MOENGAGE.EVENT.DEVICE_REMOVE_CONFIRM, this.deviceConfirmData(MOENGAGE));
                closePopup();
            } else {
                console.log('DEVICE REMOVAL FAILED')
            }
        }).catch((error) => {
            this.setState({ selectedDevices: [] })
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_REMOVE_ERROR, {
                [MIXPANEL.PARAMETER.SOURCE]: getAnalyticsSource(this.props.location.pathname),
                // [MIXPANEL.PARAMETER.DEVICE]: deviceDetail.deviceName,
            })
        })
    };

    deviceConfirmData = (analytics) => {
        return {
            [`${analytics.PARAMETER.SOURCE}`]: getAnalyticsSource(this.props.location.pathname),
        }
    }


    getIconClassName = (deviceType) => {
        if (
            deviceType.toLowerCase() === DEVICE_TYPE.ANDROID ||
            deviceType.toLowerCase() === DEVICE_TYPE.IOS ||
            deviceType.toLowerCase() === DEVICE_TYPE.WEB
        ) {
            if (deviceType.toLowerCase() === DEVICE_TYPE.WEB) {
                return 'web-icon';
            }
            return "mobile-icon";
        } else {
            return "tv-icon";
        }

    }

    handleDeviceSelect = (e,item) => {
        const exists = this.state.selectedDevices?.some(device => device.deviceNumber === item.deviceNumber);
        if (exists) {
            this.setState({ selectedDevices: this.state.selectedDevices?.filter(device => device.deviceNumber !== item.deviceNumber)});
        } else {
            this.setState({selectedDevices : [...this.state.selectedDevices, item]});
        }
    }

     isDeviceSelected = (deviceNumber) => {
        return this.state.selectedDevices?.some(device => device.deviceNumber === deviceNumber);
    };

    toastHandler = (message) => {
        toast(
            <LoginSuccessToast message={message || "Device removed successfully"}/>,
             {
                position: toast.POSITION.BOTTOM_CENTER,
                className: `login-toast-wrapper device-toast-wrapper`,
                autoClose: 2000
            },
        )
    }
    
    trackDeviceRemoveSkip = () => {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.DEVICE_REMOVE_SKIP, {
            [MIXPANEL.PARAMETER.SOURCE]: getAnalyticsSource(this.props.location.pathname),
        })
    }

    render() {
        let { subscriberDeviceList, subscriberDeviceData, isBeforeLogin, location, subTitle, history, subscriptionDetails } = this.props;
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        const checkIsLoginBefore = isBeforeLogin || location?.state?.isBeforeLogin;
        const subHeading = subscriberDeviceData?.removeDeviceVerbiage;

        let manageDevicesDetails = subscriptionDetails?.find((i) => i.subscriberId === userInfo.sId);
        let systemDetails = getSystemDetails()
        return (
            <div className={location?.state?.isBeforeLogin ? "device-management-container addPadding " : "device-management-container"} >
                <Heading
                    headingClassName="device-management-heading"
                    heading={
                        get(
                            this.props.verbiages,
                            "title"
                        ) }
                />
                <div className="device-management-sub-heading-section">
                    <Heading
                        headingClassName="logged-in"
                        heading={get(
                            this.props.verbiages,
                            "header"
                        ) 
                        }
                        subHeading={ get(
                            this.props.verbiages,
                            "subHeader"
                        ) }

                    />
                </div>
                
                
                {subscriberDeviceList && subscriberDeviceList?.length > 0 && (
                    <div className="device-section">
                        <div className="device-container">
                            {subscriberDeviceList?.map((item, index) => {
                                return (
                                    <div className="device" key={index} onClick={(e) => !item.currentDevice && !item.primary && this.handleDeviceSelect(e, item)}>
                                        <div className= "device-wrapper">
                                        <div className="device-left">
                                                <img src={item.deviceIcon
                                                    ? `${cloudinaryCarousalUrl(
                                                        "",
                                                        "",
                                                        40,
                                                        40,
                                                    )}${item.deviceIcon}`
                                                    : ""} className={this.getIconClassName(item?.deviceType)} alt='device-icon'/>
                                            <span className="device-name">
                                                {item?.deviceName}
                                            </span>
                                        </div>
                                        <div className="device-right">
                                            {item?.currentDevice && (
                                                <span className="float-right this-device">
                                                    {get(
                                                        item,
                                                        "thisDeviceText"
                                                    ) || " This Device"}
                                                </span>
                                            )}
                                            {!item?.currentDevice && !item?.primary && (
                                                <RadioButton showCheckIcon={true} checked={this.isDeviceSelected(item?.deviceNumber)} className={"delete-device float-right"} />
                                            )}
                                        </div>
                                        </div>
                                       {item?.lastActivity && <p className="last-activity" dangerouslySetInnerHTML={{
                                            __html: item?.lastActivity }}/>}
                                        {item?.primary && <p className="primary-msg">{get(
                                                item,
                                                "primaryDeviceText"
                                            )}</p>}
                                    </div>
                                );
                            })}
                        </div>
                       
                        <div className="device-count">
                            <span className="float-right">
                                {this.props.verbiages?.deviceFooterMessage}
                            </span>
                        </div>
                    </div>
                )}
                {this.props.verbiages?.ctaSingleSelectionVerbiage && <div className={`button-container ${!checkIsLoginBefore ? 'login-btn': ''}`}>
                    <Button cName="btn primary-btn" bType="button"
                        clickHandler={() => this.deleteSubscriberDevice(this.state.selectedDevices)}
                        bValue={`${this.state.selectedDevices.length > 1 ? this.props.verbiages?.ctaMultiSelectionVerbiage : this.props.verbiages?.ctaSingleSelectionVerbiage }`} 
                        disabled={this.state.selectedDevices.length < (this.props.verbiages?.minDeviceRemoveCount || 1)}/>
                </div>}
                {checkIsLoginBefore  &&
                    <p
                        onClick={() => {
                            if (isSubscriptionDiscount(history) || (isMobile.any() && location?.state?.prevPath === `/${URL.SUBSCRIPTION_DISCOUNT}`)) {
                                safeNavigation(history, URL.SUBSCRIPTION)
                            }
                            else if (isMobile.any()) {
                                notNow(this.props, MIXPANEL.VALUE.LOGIN_MANAGEDEVICES_PAGE);
                                safeNavigation(history, location?.state?.prevPath)
                                this.trackDeviceRemoveSkip()
                            }
                            else {
                                notNow(this.props, MIXPANEL.VALUE.LOGIN_MANAGEDEVICES_PAGE);
                                this.trackDeviceRemoveSkip()
                            }
                        }}
                        className="not-now">{this.props.verbiages?.secondaryButton}</p>}
                { (subscriberDeviceList && subscriberDeviceList.length === 0 && (
                    <NoDataAvailable text="No Data Found!" />
                ))}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriberDeviceList: get(
            state,
            "deviceManagement.subscriberDeviceList.data.deviceList",
        ),
        subscriberDeviceData: get(
            state,
            "deviceManagement.subscriberDeviceList.data",
        ),
        deletedSubscriberIdResponse: get(
            state.deviceManagement,
            "deletedSubscriberIdResponse",
        ),
        userProfileDetails: get(state.profileDetails, "userProfileDetails"),
        selectedPack: get(state.subscriptionDetails, "selectedTenureValue"),
        subscriptionDetails: get(state.loginReducer, 'subscriptionDetails.data.accountDetails'),
        loginSource: get(state.loginReducer, "manualLoginSource"),
        verbiages: get(state,"deviceManagement.subscriberDeviceList.data.verbiages"),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                getSubscriberDeviceList,
                deleteSubscriberDeviceList,
                openPopup,
                closePopup,
                hideFooter,
            },
            dispatch,
        ),
    };
}

DeviceManagement.propTypes = {
    getSubscriberDeviceList: PropTypes.func,
    deleteSubscriberDeviceList: PropTypes.func,
    subscriberDeviceList: PropTypes.array,
    deletedSubscriberIdResponse: PropTypes.object,
    openPopup: PropTypes.func,
    userProfileDetails: PropTypes.object,
    closePopup: PropTypes.func,
    subscriberDeviceData: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    isBeforeLogin: PropTypes.bool,
    subTitle: PropTypes.string,
    onDeviceRemoved: PropTypes.func,
    selectedPack: PropTypes.object,
    subscriptionDetails: PropTypes.object,
    hideFooter: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceManagement));

