import { io } from "socket.io-client";
import { getKey, setKey } from "@utils/storage";
import { EVENT_ROVER_ERROR_MESSAGE, LOCALSTORAGE } from "@constants";
import ENV_CONFIG from '@config/environment/index';
import { getEventRoverCommonHeaders, getPubnubChannelName, handlePubnubData, isUserloggedIn } from "@utils/common";
import { fetchJWTTokenForEventRover, fetchLatestEventFromEventRover } from "@containers/Login/LoginCommon";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import * as moment from "moment";

let eventRoverSocket = null;
let eventRoverSocketId = null;

export default (channel, callback, login) => {
    init(channel, callback, login);
}

const init = async (channel, callback, login) => {
    const currentTime = new Date().getTime(),
        authToken = getKey(LOCALSTORAGE.EVENT_ROVER_AUTH_TOKEN);
    if (authToken) {
        eventRoverSocket = io(
            ENV_CONFIG.EVENT_ROVER_HOST_URL, {
            transports: ['websocket'],
            path: "/event-rover/subscriber/socket.io",
            // reconnection: true,
            // reconnectionAttempts: 5,
            // reconnectionDelay: 1000, (in ms)
            // reconnectionDelayMax: 5000,(in ms) 
            // timeout: 20000,
            auth: {
                "token": authToken,
                "X-Dev": getEventRoverCommonHeaders(),
                "X-Ws-Request-Time": currentTime,
                "X-Channels": JSON.stringify([channel]),
            },
        });
        eventRoverSocket && setEventRoverListener(channel, callback, login);
    }
    else {
        isUserloggedIn() && !login && await retryConnectionWithEventRover(channel, callback, login);
    }
};

const retryConnectionWithEventRover = async (channel, callBack, login) => {
    const response = await fetchJWTTokenForEventRover();
    response?.token && init(channel, callBack, login);
}

const setEventRoverListener = (channel, callback, login) => {
    // Socket.IO will automatically try to reconnect when a connection error occurs
    eventRoverSocket.on('connect_error', async (error) => {
        console.log("Event Rover connect_error event :", error?.message);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.EVENT_ROVER_CONNECTION_FAILED, {
            [MIXPANEL.PARAMETER.CHANNEL_NAME]: channel,
            [MIXPANEL.PARAMETER.DTH_STATUS]: JSON.parse(getKey(LOCALSTORAGE.USER_INFO))?.dthStatus,
            [MIXPANEL.PARAMETER.X_DEV]: getEventRoverCommonHeaders(),
            [MIXPANEL.PARAMETER.SOCKET_ID]: eventRoverSocketId,
            [MIXPANEL.PARAMETER.ERROR]: error?.message,
        });
        if (error?.message === EVENT_ROVER_ERROR_MESSAGE.JWT_EXPIRED) {
            retryConnectionWithEventRover(channel, callback, login); // Manual reconnect on jwt token expiry
        }
    });

    eventRoverSocket.on('connect_failed', async (error) => {
        console.log("Event Rover connect_failed event :", error?.message);
    });

    eventRoverSocket.on('connect', function () {
        if (eventRoverSocket.connected) {
            eventRoverSocketId = eventRoverSocket?.id;
            console.log(`Event Rover connected successfully on channel : ${channel} and socket ID : ${eventRoverSocket.id}`);
            setKey(LOCALSTORAGE.CURRENT_PUBNUB_CHANNEL, channel);
            let mixpanelData = {
                [MIXPANEL.PARAMETER.CHANNEL_NAME]: channel,
                [MIXPANEL.PARAMETER.DTH_STATUS]: JSON.parse(getKey(LOCALSTORAGE.USER_INFO))?.dthStatus,
                [MIXPANEL.PARAMETER.X_DEV]: getEventRoverCommonHeaders(),
                [MIXPANEL.PARAMETER.SOCKET_ID]: eventRoverSocketId,
            }
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.EVENT_ROVER_CONNECTED, mixpanelData);
            isUserloggedIn() && fetchMessages(login);
        } else {
            console.log('Event Rover not connected yet');
        }
    });

    eventRoverSocket.on('push', (data, publishMetaData) => {
        setKey(LOCALSTORAGE.PUBNUB_RECEIVED, JSON.stringify(true)); // Key mainted for pubnub miss issue on login in event rover 
        const currentTime =  moment().toISOString();
        let xDev = getEventRoverCommonHeaders(),
            ackMetaData = {
                eventId: publishMetaData?.eventId,
                channel: publishMetaData?.channel,
                pubAt: publishMetaData?.pubAt,
                ackAt: currentTime
            };
        console.log("Event Rover Acknowledgment sent to BE after receiving push", ackMetaData);
        eventRoverSocket.emit('ack', JSON.parse(xDev), ackMetaData);
        callback(data);
    });

    eventRoverSocket.on('disconnect', (reason) => {
        console.log("Event Rover disconnect event :", reason, eventRoverSocket);
        if (reason === EVENT_ROVER_ERROR_MESSAGE.MANUAL_DISCONNECT) {
            console.log("Event Rover server disconnected by client manually");
        } else {
            console.log("Event Rover Attempting to reconnect...");
        }
        let mixpanelData = {
            [MIXPANEL.PARAMETER.CHANNEL_NAME]: channel,
            [MIXPANEL.PARAMETER.DTH_STATUS]: JSON.parse(getKey(LOCALSTORAGE.USER_INFO))?.dthStatus,
            [MIXPANEL.PARAMETER.X_DEV]: getEventRoverCommonHeaders(),
            [MIXPANEL.PARAMETER.REASON]: reason,
            [MIXPANEL.PARAMETER.SOCKET_ID]: eventRoverSocketId,
        }
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.EVENT_ROVER_DISCONNECTED, mixpanelData);
    });

    eventRoverSocket.on("reconnect_attempt", (attemptNumber) => {
        console.log('Event Rover reconnect_attempt event :', attemptNumber);
    });

    eventRoverSocket.on("reconnect_error", (error) => {
        console.log("Event Rover reconnect_error event :", error);
    });

    eventRoverSocket.on("reconnect_failed", () => {
        console.log("Event Rover reconnect_failed after max attempts");
    });

    eventRoverSocket.on("reconnect", (attemptNumber) => {
        console.log(`Event Rover Successfully reconnected after ${attemptNumber} attempts`);
    });
};

export const removeEventRoverListener = (channelToRemove) => {
    if (eventRoverSocket) {
        eventRoverSocket.disconnect();
        eventRoverSocket.removeAllListeners();
        eventRoverSocket = null;
        eventRoverSocketId = null;
    }
};

export const fetchMessages = async (login) => {
    await fetchLatestEventFromEventRover(login).then(data => {
        data && handlePubnubData(data, 'from history for event rover app');
    });
}